<template>
    <div id="patient-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <form @submit.prevent="getList()">
                <div class="mb-3 row">
                    <div class="col-sm-3 ms-auto">
                        <date-range-picker title="Date Range" v_model="date_range"/>
                    </div>
                    <div class="col-md-1"></div>
                    <div class="col-sm-3">
                        <drop-down title="Staff" v_model="user"/>
                    </div>
                    <div class="col-sm-2 mt-2 mt-sm-0">
                        <input type="submit" class="btn btn-primary w-100" value="Searching">
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Date</th>
                            <th scope="col">Staff</th>
                            <th scope="col">Login</th>
                            <th scope="col">Logout</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="lists.length">
                            <tr v-for="(list, index) in lists" class="align-middle">
                                <th scope="row">{{ table.pagination.from + index }}</th>
                                <td>{{ list.date | dateFormat }}</td>
                                <td>{{ list.user ? list.user.name : '' }}</td>
                                <td>{{ list.log_in | timeFormat }}</td>
                                <td> {{ list.log_out | timeFormat }}</td>
                                <td>
                                    <delete-action v-if="$root.$can('attendance', 'attendance-delete')" class="w-auto"
                                                   :id="list.id"/>
                                </td>
                            </tr>
                        </template>
                        <template v-if="!lists.length">
                            <tr>
                                <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
                <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";
import DateRangePicker     from "@/components/picker/DateRangePicker";
import moment              from "moment";

export default {
    name      : "AttendanceList",
    components: {DateRangePicker, Pagination},
    data      : () => ({
        lists: [],
        form : {
            date_range: [
                moment().startOf('month').format("YYYY-MM-DD"),
                moment(new Date()).format("YYYY-MM-DD"),
            ],
            user      : '',
        },
        table: {
            per_page  : 10,
            date_range: '',
            user      : '',
            pagination: {
                current_page: 1
            }
        }
    }),
    mounted() {
        this.getList();
    },
    methods: {
        //-------CRUD------
        getList() {
            let params = {
                ...this.table,
                page      : this.table.pagination.current_page,
                date_range: this.form.date_range,
                user_id   : this.form.user ? this.form.user.id : '',
            };
            ApiService.get('/attendances', {params: params}).then(({data}) => {
                this.lists            = data.data;
                this.table.pagination = data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/attendances/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        //-------CRUD--------
    }
}
</script>
